<template>
  <div
    class="loader-wrapper"
  >
    <div class="loader">
      <img
        v-for="image in 3"
        :key="image"
        class="loader-img"
        src="@/assets/images/fuelDispenser.svg"
        alt="Автозаправочная колонка"
      >
    </div>
  </div>
</template>

<script>
export default {
    name: 'Loading',
}
</script>

<style scoped>
  .loader-wrapper {
    margin: 240px auto 0;
  }

  .loader {
    margin: 0 auto;
  }

  .loader-img {
    width: 70px;
    height: 70px;
    animation: bounce 1.5s infinite ease-in-out;
  }

  .loader-img:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loader-img:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
</style>