import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from "./views/Main";
import Error from "./views/Error";
import { getCookie } from './helpers/cookieHelpers';

Vue.use(VueRouter);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

if (params['token'] && params['lat'] && params['lon']) {
  document.cookie = `user-token=${params['token']}; path=/`;
  document.cookie = `lat=${params['lat']}; path=/`;
  document.cookie = `lon=${params['lon']}; path=/`;
}

const ifAuthenticated = (to, from, next) => {
  const token = getCookie('user-token');
  token.length > 0 ? next() : next('/error');
};

const routes = [
  {
    path: '/',
    component: Main,
    beforeEnter: ifAuthenticated,
    meta: { requiresAuth: true }
  },
  {
    path: '/error',
    component: Error,
    meta: { guest: true }
  },
];

export default new VueRouter({
  mode: 'history',
  routes
});
