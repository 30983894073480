import { apiClient, apiHost } from "@/store/api";

export default {
  getStations(params) {
    return apiClient.get(apiHost + '/v1/stations', { params });
  },
  getStationsBrand() {
    return apiClient.get(apiHost + '/v1/stations/brands',)
  },
  getStationInformation(id) {
    return apiClient.get(`${apiHost}/v1/stations/${id}`);
  }
}
