import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import balance from "@/store/balance";
import order from "@/store/order";
import stations from "@/store/stations";
import fuels from "@/store/fuels";
import notification from '@/store/notification';

export const store = new Vuex.Store({
    modules: {
      balance,
      order,
      stations,
      fuels,
      notification,
    }
});
