<template>
  <div>
    <div class="d-flex justify-content-center">
      <component
        :is="getImageByStatus"
        :style="getImageStyle"
      />
    </div>
    <div
      class="title"
      :style="getTextColor"
    >
      {{ getTitleOrderByStatus }}
    </div>
    <div
      v-if="orderUser.status === ORDER_STATUS.COMPLETED"
      class="price-order"
    >
      - {{ orderUser.sum_paid_completed || orderUser.sum }}&nbsp;₽
    </div>
    <div
      v-if="orderUser.status !== ORDER_STATUS.FUELING"
      class="subtitle"
    >
      {{ getSubtitleOrderByStatus }}
    </div>
    <button
      v-if="orderUser.status !== ORDER_STATUS.FUELING && orderUser.status !== ORDER_STATUS.POST_PAYMENT_FAILED"
      class="button"
      :style="getButtonStyles"
      @click="actionFromOrder"
    >
      {{ getButtonNameByStatus }}
    </button>
  </div>
</template>

<script>
import ErrorFuelDispenser from "@/components/icon/ErrorFuelDispenser.vue";
import FuelingProcess from "@/components/icon/FuelingProcess.vue";
import SuccessFueling from "@/components/icon/SuccessFueling.vue";
import FuelDispenser from "@/components/icon/FuelDispenser.vue";
import { ORDER_STATUS } from "../store/constant";

export default {
  name: "FuelingStatus",
  components: { FuelingProcess, SuccessFueling, ErrorFuelDispenser, FuelDispenser },
  props: {
    order: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      orderUser: this.order,
      ORDER_STATUS,
    };
  },
  computed: {
    getImageByStatus() {
      if (this.orderUser.status === ORDER_STATUS.FUELING) {
        return FuelingProcess;
      } else if (this.orderUser.status === ORDER_STATUS.COMPLETED) {
        return SuccessFueling;
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_STATION || this.orderUser.status === ORDER_STATUS.CANCELLED_BY_USER || this.orderUser.status === ORDER_STATUS.POST_PAYMENT_FAILED) {
        return ErrorFuelDispenser;
      } else {
        return FuelDispenser;
      }
    },

    getTitleOrderByStatus() {
      if (this.orderUser.status === ORDER_STATUS.FUELING) {
        const currentTime = Math.floor(Date.now() / 1000);

        // больше 2х минут
        if (currentTime - this.orderUser.created_at > 120) {
          return "Всё почти готово, оставайтесь на месте.";
        }
        return "Идёт заправка";
      } else if (this.orderUser.status === ORDER_STATUS.COMPLETED) {
        return "Заправка завершена";
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_STATION || this.orderUser.status === ORDER_STATUS.CANCELLED_BY_USER) {
        return "Заправка отменена";
      } else if (this.orderUser.status === this.orderUser.status === ORDER_STATUS.POST_PAYMENT_FAILED) {
        return "Недостаточно средств";
      } else if (this.order.is_fueling_callback_received) {
        return "Готово к заправке";
      } else {
        return "Не снимайте пистолет";
      }
    },

    getSubtitleOrderByStatus() {
      if (this.orderUser.status === ORDER_STATUS.COMPLETED) {
        return (this.orderUser.litre_completed || this.orderUser.litres) + " л";
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_STATION) {
        return this.orderUser.reason ?? "К сожалению АЗС отменила вашу заправку";
      } else if (this.orderUser.status === this.orderUser.status === ORDER_STATUS.CANCELLED_BY_USER) {
        return "Вы отменили заправку, деньги вернутся на баланс в течение двух дней";
      } else if (this.orderUser.status === this.orderUser.status === ORDER_STATUS.POST_PAYMENT_FAILED) {
        return "Пожалуйста пополните баланс или оплатите заказ на кассе";
      } else if (this.order.is_fueling_callback_received) {
        return "Дождитесь заправщика или заправьтесь самостоятельно";
      } else {
        return 'Мы передаем ваш заказ на станцию'
      }
    },

    getButtonNameByStatus() {
      if (this.orderUser.status === ORDER_STATUS.COMPLETED) {
        return "На главную";
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_STATION) {
        return "Попробовать заново";
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_USER) {
        return "Вернуться на главную";
      } else {
        return "Отменить";
      }
    },

    getButtonBackgroundColor() {
      return this.getButtonNameByStatus === "Отменить" ? "#F4F5FA" : "#00A3FF";
    },

    getButtonTextColor() {
      return this.getButtonBackgroundColor === "#F4F5FA" ? "#222A34" : "#F4F5FA";
    },

    getButtonStyles() {
      return {
        backgroundColor: this.getButtonBackgroundColor,
        color: this.getButtonTextColor,
      };
    },

    getTextColor() {
      return {
        color: this.orderUser.status === ORDER_STATUS.FUELING ? '#F4F5FA' : '#222A34'
      };
    },

    getImageStyle() {
      return {
        marginTop: this.orderUser.status === ORDER_STATUS.FUELING ? '100' : this.orderUser.status === ORDER_STATUS.COMPLETED ? '92' : '70',
        marginBottom: this.orderUser.status === ORDER_STATUS.FUELING ? '20' : this.orderUser.status === ORDER_STATUS.COMPLETED ? '54' : '20',
      };
    },
  },
  watch: {
    order: {
      handler(newOrder) {
        this.orderUser = newOrder;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    actionFromOrder() {
      if (this.orderUser.status === ORDER_STATUS.COMPLETED) {
        return this.$emit('clearOrder');
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_STATION) {
        return this.$emit("clearOrder");
      } else if (this.orderUser.status === ORDER_STATUS.CANCELLED_BY_USER) {
        return this.$emit('clearOrder');
      } else {
        return this.$emit('cancelOrder', this.orderUser.id);
      }
    },
  }
};
</script>

<style scoped>
  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .price-order {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
  }

  .subtitle {
    font-weight: 300;
    max-width: 400px;
    margin: 8px auto;
  }

  .button {
    position: absolute;
    padding: 0;
    border: none;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    height: 52px;
    max-width: 400px;
    min-width: 330px;
  }
</style>
