<template>
  <div class="rules-container">
    <span class="title">Правила заправки СУГ:</span>
    <ul class="rules-list">
      <li class="rules-item">
        Высадите пассажиров
      </li>
      <li class="rules-item">
        Выключите двигатель
      </li>
      <li class="rules-item">
        Поднимите капот
      </li>
      <li class="rules-item">
        Подтвердите, что срок освидетельствования баллона не истек: предъявите свидетельство на установку ГБО или Акт об освидетельствовании (опрессовке) ГБО*
      </li>
      <li class="rules-item">
        Все операции при заправке автотранспорта СУГ производятся наполнителем баллонов МтАЗС только в присутствии водителя
      </li>
      <li class="rules-item">
        На одном заправочном островке производится единовременная заправка только одного автомобиля
      </li>
      <li class="rules-item">
        Переполнение баллона не допускается
      </li>
      <li class="rules-item">
        Включать двигатель допускается только после отсоединения заправочного рукава и установки заглушки на заправочное устройство
      </li>
      <li class="rules-item">
        Если двигатель автомобиля после заправки СУГ не заводится или дает сбои, автомобиль необходимо откатить на расстояние не менее 15 метров от заправочной колонки
      </li>
    </ul>
    <div class="d-flex justify-content-center">
      <button
        class="button"
        @click="closeModal"
      >
        Понятно
      </button>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: "FuelingRules",
    methods: {
      closeModal() {
        this.$emit("closeModal");
      },
    },
  };
</script>
  
<style scoped>
  .rules-container {
    text-align: left;
  }
  
  .title {
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }
  
  .rules-list {
    padding-left: 20px;
    height: 400px;
    overflow: auto;
    margin: 6px 0;
  }
  
  .rules-item {
    margin-bottom: 8px;
  }
  
  .button {
    display: block;
    padding: 10px 20px;
    width: 100%;
    max-width: 340px;
  }
</style>
  