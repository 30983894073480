<template>
  <div>
    <div
      id="fuel-modal"
      class="fuel-amount"
    >
      <div
        ref="modal"
        class="information-block"
        :style="getBackgroundColor"
        :class="{ open: isOpen, closed: !isOpen }"
      >
        <div
          @touchstart.capture="handleTouchStart"
          @touchmove.capture="handleTouchMove"
          @touchend.capture="handleTouchEnd"
        >
          <div
            class="d-flex justify-content-center mb-3"
            style="height: 14px; width: 100%"
          >
            <component
              :is="getSwipeImageByStatus"
              class="swipe"
            />
          </div>
          <div class="d-flex justify-content-between">
            <div
              v-if="!order || !order.status"
              class="link d-flex justify-content-center"
              @click="clickButtonBack"
            >
              <BackIcon class="image-back" />
            </div>
            <div
              v-else
              style="width: 40px"
            />
            <div class="ml-1">
              <div>
                <div
                  class="title"
                  :style="getTextColor"
                >
                  {{ getTitle }}
                </div>
                <div
                  class="subtitle"
                  :style="getTextColor"
                >
                  {{ getSubtitle }}
                </div>
              </div>
            </div>
            <div style="width: 40px" />
          </div>
        </div>
        <div class="text-center">
          <Loading v-if="stationInfo === null" />
          <component
            :is="getComponent"
            v-else
            :fuel-dispensers="stationInfo.columns"
            :fuel-list="fuelList"
            :litres="litres"
            :sum="sum"
            :fuel="fuel"
            :sum-order="totalSumOrder"
            :order="order"
            :bonuses="bonuses"
            :balance="balance"
            :is-bonuses-use="isBonusesUse"
            :station-info="stationInfo"
            @selectFuelDispenser="selectFuelDispenser"
            @selectFuel="selectFuel"
            @goToPaymentOrder="goToPaymentOrder"
            @paymentFueling="paymentFueling"
            @paymentOrder="paymentOrder"
            @cancelOrder="cancelOrder"
            @createOrder="createOrder"
            @clearOrder="onClosed"
            @useBonuses="useBonusesInPostpayOrder"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectFuelDispenser from '@/components/SelectFuelDispenser.vue';
import SelectFuel from '@/components/SelectFuel.vue';
import AmountSelector from '@/components/AmountSelector.vue';
import PaymentFueling from '@/components/PaymentFueling.vue';
import FuelingStatus from '@/components/FuelingStatus.vue';
import PreFuelingAlert from '@/components/PreFuelingAlert.vue';
import Loading from '@/components/common/Loading.vue';
import { ORDER_STATUS } from '@/store/constant';
import BackIcon from '@/components/icon/BackIcon.vue';
import SwipeIcon from '@/components/icon/SwipeIcon.vue';
import SwipeIconWhite from '@/components/icon/SwipeIconWhite.vue';

export default {
  name: 'ProcessFuelingCar',
  components: { SelectFuelDispenser, SelectFuel, AmountSelector, PaymentFueling, FuelingStatus, PreFuelingAlert, Loading, BackIcon, SwipeIcon },
  props: {
    stationInfo: {
      type: Object,
      default: null
    },
    bonuses: {
      type: Number,
      default: null
    },
    balance: {
      type: Number,
      default: null
    },
    onCloseModal: {
      type: Function,
      default: null
    },
    activeOrder: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      fuelDispenser: null,
      fuel: null,
      fuelList: [],
      litres: 0,
      sum: 0,
      totalSumOrder: 0,
      order: this.activeOrder || null,
      isOpen: true,
      touchStartY: 0,
      touchCurrentY: 0,
      isDragging: false,
      isAccepted: false,
      rublesInOrder: 0,
      bonusesInOrder: 0,
      intervalId: null,
      isBonusesUse: false,
    };
  },
  computed: {
    getTitle() {
      if (this.fuelDispenser && this.fuel && !this.isAccepted) {
        return `${this.fuelDispenser.number} колонка`;
      }
      if (this.litres > 0 && (!this.order || !this.order.id || this.order.status === ORDER_STATUS.CANCELLED_BY_STATION || this.order.status === ORDER_STATUS.CANCELLED_BY_USER)) {
        return 'Оплата';
      }
      if (this.stationInfo) {
        if (this.fuelDispenser && (this.fuelDispenser.id > 0 || (this.order && this.order.id > 0))) {
          return this.fuel ? this.fuel.name : `${this.fuelDispenser.number} колонка`;
        }
        return this.stationInfo.name;
      }
      return '';
    },

    getSubtitle() {
      if (this.litres > 0 && (!this.order || !this.order.id)) {
        return this.fuel ? `${this.fuel.name}, ${this.fuelDispenser.number} колонка` : '';
      }
      if ((this.fuel && !this.isAccepted)) {
        return `${this.fuel.name}, ${this.stationInfo.name}`;
      }
      if (this.stationInfo) {
        if (this.fuelDispenser && (this.fuelDispenser.id > 0 || (this.order && this.order.id > 0))) {
          return this.fuel ? `${this.fuelDispenser.number} колонка` : this.stationInfo.name;
        }
        return this.stationInfo.address;
      }
      if (this.order && (this.order.status === ORDER_STATUS.CANCELLED_BY_STATION || this.order.status === ORDER_STATUS.CANCELLED_BY_USER)) {
        return this.fuel ? `${this.litres} литров, ${this.fuel.type}` : '';
      }
      return '';
    },

    getComponent() {
      if (this.order) {
        if (this.order.status === ORDER_STATUS.POST_PAYMENT) return PreFuelingAlert;
        return this.order.status ? FuelingStatus : PaymentFueling;
      }

      if (this.litres > 0 && this.sum > 0) return PaymentFueling;

      if (this.fuel) {
        return !this.isAccepted 
          ? PreFuelingAlert 
          : !this.litres && !this.sum 
            ? AmountSelector 
            : null;
      }

      return this.fuelDispenser ? SelectFuel : SelectFuelDispenser;
    },
    
    getSwipeImageByStatus() {
      return this.order?.status === ORDER_STATUS.FUELING
        ? SwipeIconWhite
        : SwipeIcon;
    },

    getTextColor() {
      return {
        color: this.order?.status === ORDER_STATUS.FUELING ? '#F4F5FA' : '#222A34'
      };
    },

    getBackgroundColor() {
      return {
        background: this.order?.status === ORDER_STATUS.FUELING ? 'linear-gradient(360deg, #6E24CC -25.61%, #02A1FA 100%)' : 'white'
      };
    },
  },
  mounted() {
    if (this.activeOrder !== null) {
      this.startInterval();
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    startInterval() {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          this.stationInfo?.postpay ? this.getPostpayOrder() : this.getOrder();
        }, 3000);
      }
    },

    selectFuelDispenser(fuelDispenser) {
      this.fuelDispenser = fuelDispenser;
      this.fuelList = fuelDispenser.fuels;
    },

    selectFuel(fuel) {
      this.fuel = fuel;
      if (this.stationInfo?.postpay) {
        this.order ? this.updatePostpayOrder() : this.createPostpayOrder();
      }
    },

    paymentFueling(litres, sum) {
      this.litres = litres;
      this.sum = sum;
    },

    goToPaymentOrder(isAccepted) {
      this.isAccepted = isAccepted;
    },

    onClosed() {
      this.resetState();
      this.onCloseModal && this.onCloseModal();
      clearInterval(this.intervalId);
      this.order = null;
    },

    resetState() {
      this.fuelDispenser = null;
      this.fuel = null;
      this.fuelList = [];
      this.order = null;
      this.litres = 0;
      this.sum = 0;
      this.isOpen = true;
      this.isAccepted = false;
    },

    clickButtonBack() {
      if (this.litres || this.sum) {
        this.clearPaymentDetails();
      } else if (this.isAccepted) {
        this.isAccepted = false;
      } else if (this.fuel) {
        this.fuel = null;
      } else if (this.fuelDispenser) {
        this.fuelDispenser = null;
      } else {
        this.closeModal();
      }
    },

    clearPaymentDetails() {
      this.litres = 0;
      this.sum = 0;
    },

    closeModal() {
      this.$root.$emit('bv::hide::modal', 'fuel-modal');
      this.onClosed();
    },

    paymentOrder(litres, sumOrder, rubles, bonuses) {
      this.litres = litres;
      this.totalSumOrder = sumOrder;
      this.rublesInOrder = rubles;
      this.bonusesInOrder = bonuses;
      this.createOrder();
    },

    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
      this.touchCurrentY = this.touchStartY;
      this.isDragging = true;
    },

    handleTouchMove(event) {
      if (this.isDragging) {
        this.touchCurrentY = event.touches[0].clientY;
      }
    },

    handleTouchEnd() {
      this.isDragging = false;
      const swipeThreshold = 50;

      if (Math.abs(this.touchStartY - this.touchCurrentY) > swipeThreshold) {
        this.isOpen = this.touchStartY > this.touchCurrentY;
      }
    },

    // Все что касается обычного заказа
    createOrder() {
      this.$store.dispatch('order/createOrder', {
        station_id: this.stationInfo?.id ?? this.order?.station_id,
        column_id: this.fuelDispenser?.id ?? this.order?.column_id,
        fuel_id: this.fuel?.id ?? this.order?.fuel_id,
        litres: this.litres ?? this.order?.litres,
        fuel_price: this.fuel?.price ?? this.order?.fuel_price,
        sum: this.totalSumOrder ?? this.order?.sum,
        rubles: this.rublesInOrder ?? this.order?.rubles,
        bonuses: this.bonusesInOrder ?? this.order?.bonuses,
      }).then(() => {
        this.order = this.$store.getters['order/getOrder']
        this.startInterval();
      })
    },

    cancelOrder(orderId) {
      this.$store.dispatch('order/cancelOrder', orderId);
    },

    async getOrder() {
      await this.$store.dispatch('order/getOrder', this.order.id).then(() => {
        this.order = this.$store.getters['order/getOrder'];
        if (this.order.status === ORDER_STATUS.COMPLETED ||  this.order.status === ORDER_STATUS.CANCELLED_BY_USER || this.order.status === ORDER_STATUS.CANCELLED_BY_STATION) {
          clearInterval(this.intervalId);
        }
      })
    },

    // Все что касается постоплатного заказа
    createPostpayOrder() {
      this.$store.dispatch('order/createPostpayOrder', {
        station_id: this.stationInfo?.id,
        column_id: this.fuelDispenser?.id,
        fuel_id: this.fuel?.id,
        bonuses: this.isBonusesUse ? this.bonuses : null,
      }).then(() => {
        this.order = this.$store.getters['order/getOrder']
        this.startInterval();
      });
    },

    updatePostpayOrder() {
      this.$store.dispatch('order/updatePostpayOrder', {
        order_id: this.order.id,
        station_id: this.stationInfo?.id,
        column_id: this.fuelDispenser?.id,
        fuel_id: this.fuel?.id,
        bonuses: this.isBonusesUse ? this.bonuses : null,
      }).then(() => {
        this.order = this.$store.getters['order/getOrder'];
      });
    },

    async getPostpayOrder() {
      await this.$store.dispatch('order/getPostpayOrder', this.order.id).then(() => {
        this.order = this.$store.getters['order/getOrder'];
        if (this.order.status === ORDER_STATUS.COMPLETED ||  this.order.status === ORDER_STATUS.CANCELLED_BY_USER || this.order.status === ORDER_STATUS.CANCELLED_BY_STATION) {
          clearInterval(this.intervalId);
        }
      })
    },

    useBonusesInPostpayOrder(isBonusesUse) {
      this.isBonusesUse = isBonusesUse;
      this.updatePostpayOrder();
    }
  },
};
</script>

<style scoped>
  .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
  }

  .fuel-amount {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 200vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1001;
  }

  .information-block {
    position: fixed;
    bottom: -100%;
    width: 100vw;
    z-index: 1001;
    padding: 0 16px 16px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: bottom 0.3s ease-in-out;
    height: 638px;
  }

  .information-block.open {
    bottom: 0;
  }

  .information-block.closed {
    bottom: -438px;
  }
</style>