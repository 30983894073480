<template>
  <div class="error-container">
    <div>
      <img
        style="height: 60px; width: auto;"
        src="@/assets/images/ImPayLogo.webp"
        alt="ImPay Logo"
      >
    </div>
    <h2 class="error-message">
      Сессия не активна
    </h2>
    <p class="error-description">
      Пожалуйста, войдите в систему, чтобы продолжить.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error',
};
</script>

<style scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  text-align: center;
}

.error-message {
  font-size: 32px;
  margin: 10px 0;
}

.error-description {
  font-size: 18px;
  max-width: 80%;
  word-wrap: break-word;
  margin: 0;
}
</style>
