import orderService from "@/services/orderService";
import { forcePageErrorByStatus } from "@/helpers/cookieHelpers";

export default {
  namespaced: true,

  state: {
    order: null,
    station: null,
    isLoading: false,
  },

  mutations: {
    SET_ORDER(state, data) {
      state.order = data && data.order ? data.order : data;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_ACTIVE_ORDER(state, data) {
      state.order = data && data.order ? data.order : data;
      state.station = data && data.station ? data.station : data;
    },
  },

  actions: {
    async handleRequest({ commit, dispatch }, { action, mutation, payload }) {
      commit("SET_LOADING", true);
      try {
        const response = await action(payload);
        if (response.data) {
          commit(mutation, response.data);
          dispatch('notification/addSuccess', response.data.message, { root: true });
        }
      } catch (error) {
        if (error) {
          if (error.response.status !== 404) {
            dispatch('notification/addError', error, { root: true });
          }
          forcePageErrorByStatus(error);
        }
      } finally {
        commit("SET_LOADING", false);
      }
    },

    createOrder({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: orderService.createOrder,
        mutation: "SET_ORDER",
        payload: data,
      });
    },

    checkActiveOrder({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: orderService.checkActiveOrder,
        mutation: "SET_ACTIVE_ORDER",
        payload: data,
      });
    },

    getOrder({ dispatch }, id) {
      return dispatch("handleRequest", {
        action: orderService.getOrder,
        mutation: "SET_ORDER",
        payload: id,
      });
    },

    cancelOrder({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: orderService.cancelOrder,
        mutation: "SET_ORDER",
        payload: data,
      });
    },

    createPostpayOrder({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: orderService.createPostpayOrder,
        mutation: "SET_ORDER",
        payload: data,
      });
    },

    updatePostpayOrder({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: orderService.updatePostpayOrder,
        mutation: "SET_ORDER",
        payload: data,
      });
    },
    
    getPostpayOrder({ dispatch }, id) {
      return dispatch("handleRequest", {
        action: orderService.getPostpayOrder,
        mutation: "SET_ORDER",
        payload: id,
      });
    },
  },

  getters: {
    getOrder(state) {
      return state.order;
    },
    getLoadingState(state) {
      return state.isLoading;
    },
    getStationForActiveOrder(state) {
      return state.station;
    },
  },
};
