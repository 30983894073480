<template>
  <div>
    <div
      v-if="successMessage"
      class="message"
      style="background-color: rgb(43, 175, 81)"
    >
      {{ successMessage }}
    </div>
    <div
      v-if="errorMessage"
      class="message"
      style="background-color: rgb(175, 45, 43)"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'Notification',
    data() {
      return {
        successMessage: null,
        errorMessage: null,
      }
    },
    computed: {
      successMessageFromStore() {
          return this.$store.getters['notificationModule/getSuccessMessage'];
      },
      errorMessageFromStore() {
        return this.$store.getters['notificationModule/getErrorMessage'];
      },
    },
    watch: {
      successMessageFromStore(newMessage) {
        this.errorMessage = null;
        this.successMessage = newMessage;
        if (newMessage) {
          setTimeout(() => {
            this.successMessage = null;
          }, 3000);
        }
      },
      errorMessageFromStore(newMessage) {
        this.successMessage = null;
        this.errorMessage = newMessage;
        if (newMessage) {
          setTimeout(() => {
            this.errorMessage = null;
          }, 3000);
        }
      },
    },
  }
</script>
  
<style scoped>
  .message {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2100;
    width: 340px;
    display: flex;
    justify-content: center;
    padding: 16px 16px;
    border-radius: 8px;
    color: white;
  }
</style>