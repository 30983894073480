import router from "@/router";

export const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : '';
};

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function forcePageErrorByStatus(error) {
  if (error.response) {
    if (error.response.data.code === 401) {
      deleteCookie("lat");
      deleteCookie("lon");
      deleteCookie("user-token");

      router.push("/error");
    }
  }

  return Promise.reject(error);
}