<template>
  <div>
    <div class="icon">
      <FuelDispenser />
    </div>
    <div class="title">
      {{ getTitle }}
    </div>
    <div class="subtitle">
      <span v-if="fuel.name === 'ПРОПАН' || fuel.name === 'МЕТАН'">
        Пользуясь сервисом, вы подтверждаете, что ознакомлены и согласны с
        <span
          class="link"
          @click="openModal"
        >
          Правилами заправки
        </span>
      </span>
      <span v-else>
        {{ getSubtitle }}
      </span>
    </div>
    <button
      v-if="!stationInfo?.postpay"
      class="button"
      @click="goToPaymentOrder"
    >
      Хорошо
    </button>
    <div
      v-if="stationInfo?.postpay"
      class="select-bonuses"
    >
      <div class="checkbox-wrapper-31 checkbox">
        <input
          v-model="localIsBonusesUse"
          type="checkbox"
          @change="useBonuses"
        >
        <label>
          <svg viewBox="0 0 35.6 35.6">
            <circle
              class="background"
              cx="17.8"
              cy="17.8"
              r="17.8"
            />
            <circle
              class="stroke"
              cx="17.8"
              cy="17.8"
              r="14.37"
            />
            <polyline
              class="check"
              points="11.78 18.12 15.55 22.23 25.17 12.87"
            />
          </svg>
        </label>
      </div>
      <div>
        <div class="subtitle">
          Использовать бонусы при оплате?
        </div>
      </div>
    </div>
    <div
      v-if="dialog"
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal-content">
        <FuelingRules @closeModal="closeModal" />
      </div>
    </div>
  </div>
</template>

<script>
import FuelDispenser from "@/components/icon/FuelDispenser.vue";
import FuelingRules from "@/components/common/FuelingRules.vue";

export default {
  name: "PreFuelingAlert",
  components: { FuelDispenser, FuelingRules },
  props: {
    stationInfo: {
      type: Object,
      default: null
    },
    isBonusesUse: {
      type: Boolean,
      default: null
    },
    fuel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localIsBonusesUse: this.isBonusesUse,
      dialog: false,
    };
  },
  computed: {
    getTitle() {
      if (["ПРОПАН", "МЕТАН"].includes(this.fuel.name)) {
        return "Заправку газом осуществляет только сотрудник АЗС";
      }
      return this.stationInfo?.postpay 
        ? "Вставьте пистолет в бак" 
        : "Не снимайте пистолет до оплаты";
    },

    getSubtitle() {
      return this.stationInfo?.postpay ? "Начните заправку" : "Сначала нужно оплатить, потом заправляться";
    },
  },
  methods: {
    goToPaymentOrder() {
      this.$emit('goToPaymentOrder', true);
    },

    useBonuses() {
      this.$emit('useBonuses', this.localIsBonusesUse);
    },

    openModal() {
      this.dialog = true;
    },

    closeModal() {
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
  .icon {
      display: flex;
      justify-content: center;
      margin-top: 70px;
      margin-bottom: 16px;
    }

  .button {
    position: absolute;
    padding: 0;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    max-width: 400px;
    min-width: 330px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    max-width: 400px;
    margin: 0 auto 8px;
    line-height: 1.2;
  }

  .subtitle {
    font-weight: 300;
    max-width: 400px;
    margin: 0 auto;
  }

  .select-bonuses {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .checkbox {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .link {
    color: #00A3FF;
    text-decoration: underline;
    background: none;
    margin: 0;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 40px;
    border-radius: 10px;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 20px;
  }
</style>
