import balanceService from "@/services/balanceService";
import { forcePageErrorByStatus } from "@/helpers/cookieHelpers";

export default {
  namespaced: true,

  state: {
    balance: null,
    bonuses: null,
  },

  mutations: {
    SET_BALANCE(state, data) {
      state.balance = data;
    },
    SET_BONUSES(state, data) {
      state.bonuses = data;
    }
  },

  actions: {
    async handleRequest({ commit }, { action, mutations, payload }) {
      try {
        const response = await action(payload);
        mutations.forEach(({ type, key }) => commit(type, response.data[key]));
      } catch (error) {
        forcePageErrorByStatus(error);
      }
    },

    getBalanceAndBonuses({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: balanceService.getBalanceAndBonuses,
        mutations: [
          { type: "SET_BALANCE", key: "money_balance" },
          { type: "SET_BONUSES", key: "bonus_balance" }
        ],
        payload: data,
      });
    }
  },

  getters: {
    getBalance(state) {
      return state.balance;
    },
    getBonuses(state) {
      return state.bonuses;
    }
  }
}
