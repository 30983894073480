<template>
  <div>
    <yandex-map
      :settings="settings"
      :coords="coords"
      :cluster-options="clusterOptions"
      :controls="['zoomControl']"
      @boundschange="updateMapTimer"
    >
      <ymap-marker
        :coords="coords"
        marker-id="my"
        hint-content="Моё местоположение"
        :icon="{
          glyph: 'AutoCircle',
          color: 'red'
        }"
      />
      <ymap-marker
        v-for="station in stations"
        :key="station.id"
        :coords="station.coords"
        :marker-id="station.id"
        :hint-content="station.name"
        :icon="{
          glyph: 'FuelStationCircle',
          color: 'blue'
        }"
        cluster-name="1"
        :balloon="{header: station.name, body: station.address}"
        @click="setSelected(station)"
      />
    </yandex-map>
    <component
      :is="getComponent"
      :stations-brand="stationsBrand"
      :fuels="fuels"
      :selected-fuel-types="selectedFuelTypes"
      :selected-station-brands="selectedStationBrands"
      :selected-station="selectedStation"
      :station-info="stationInfo"
      :balance="balance"
      :bonuses="bonuses"
      :on-close-modal="onCloseModal"
      :active-order="activeOrder"
      @updateFilter="updateFilter"
      @showStation="showStation"
      @backToMap="backToMap"
    />
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import FilterStations from "./FilterStations.vue"
import StationInformation from "./StationInformation.vue"
import ProcessFuelingCar from "./ProcessFuelingCar.vue"
import { getCookie } from '../helpers/cookieHelpers'

export default {
  name: 'YandexMapFuel',
  components: {yandexMap, ymapMarker, FilterStations, StationInformation, ProcessFuelingCar},
  data() {
    return {
      filterOpen: false,
      settings: {
        apiKey: '07e4480c-4b4c-4e27-add8-39d5b93ca5b4',
        lang: 'ru_RU',
        coordOrder: 'latlon',
        enterprise: false,
        version: '2.1'
      },
      coords: this.getInitialCoords(),
      radius: 0.1,
      clusterOptions: {
        1: {
          gridSize: 64,
          clusterDisableClickZoom: true,
          clusterOpenBalloonOnClick: true,
          clusterBalloonLayout: [
            '<div class="list">',
            '{% for geoObject in properties.geoObjects %}',
            '<div><a href=# data-action="geoObject" data-name="{{ geoObject.properties.balloonContentHeader|raw }}" class="list_item">{{ geoObject.properties.balloonContentHeader|raw }}</a></div>',
            '{% endfor %}',
            '</div>',
          ].join('')
        }
      },
      selectedStation: null,
      interval: null,
      updateTimer: null,
      activeOrder: null,
      fuelBrand: null,
      fuelStation: null,
      isFueling: false,
      selectedFuelTypes: [],
      selectedStationBrands: [],
      stations: [],
      stationsBrand: null,
      fuels: null,
      balance: null,
      bonuses: null,
      stationInfo: null,
      isAutoSelect: false,
    }
  },
  computed: {
    getComponent() {
      if (this.isFueling) {
        return ProcessFuelingCar;
      } else if (this.selectedStation) {
        return StationInformation;
      } else {
        return FilterStations;
      }
    },
  },
  mounted() {
    document.addEventListener("click", e => {
      if (e.target.dataset.action !== undefined && e.target.dataset.action === 'geoObject') {
        const name = e.target.dataset.name
        if (this.stations.some((station) => {
          if (name === station.name) {
            this.stationInfo = station;
            return true;
          }
          return false;
        })) {
          this.setSelected(this.stationInfo);
          this.showStation(this.stationInfo.id);
        }
      }
    })
    this.checkActiveOrder().then(() => {
      this.fetchStations();
      this.fetchStationsBrand();
      this.fetchFuels();
      this.getBalanceAndBonuses();
    })
  },
  methods: {
    getInitialCoords() {
      const lat = parseFloat(getCookie('lat'));
      const lon = parseFloat(getCookie('lon'));
      return [lat, lon];
    },

    async fetchStations() {
      const kmPerDegree = 80;
      const radiusInKm = this.radius * kmPerDegree;
      await this.$store.dispatch('stations/fetchStations', {
        lat: this.coords[0],
        lon: this.coords[1],
        radius: radiusInKm,
        fuelTypes: this.selectedFuelTypes,
        stationBrands: this.selectedStationBrands,
      }).then(() => {
        this.stations = this.$store.getters['stations/getStations'];
        if (this.stations && !this.isAutoSelect) {
          this.autoSelectNearbyStation();
        }
      })
    },

    async fetchStationsBrand() {
      await this.$store.dispatch('stations/fetchStationsBrand').then(() => {
        this.stationsBrand = this.$store.getters['stations/getStationsBrand']
      })
    },

    async fetchFuels() {
      await this.$store.dispatch('fuels/fetchFuels').then(() => {
        this.fuels = this.$store.getters['fuels/getFuels']
      })
    },

    async getBalanceAndBonuses() {
      await this.$store.dispatch('balance/getBalanceAndBonuses').then(() => {
        this.balance = this.$store.getters['balance/getBalance'];
        this.bonuses = this.$store.getters['balance/getBonuses'];
      })
    },

    async checkActiveOrder() {
      await this.$store.dispatch('order/checkActiveOrder').then(() => {
        this.activeOrder = this.$store.getters['order/getOrder'];
        this.selectedStation = this.$store.getters['order/getStationForActiveOrder'];
        if (this.selectedStation) {
          this.setSelected(this.selectedStation);
          this.showStation(this.selectedStation.id);
        }
      })
    },

    setSelected(station) {
      if (station) {
        this.selectedStation = station;
      } else {
        this.selectedStation = null;
      }
    },

    showStation(id) {
      this.isFueling = true;
      this.$root.$emit('bv::show::modal', 'fuel-modal', '#btnShow');
      this.$store.dispatch('stations/stationInformation', id).then(() => {
        this.stationInfo = this.$store.getters['stations/getStationInformation']
      })
    },

    updateMapTimer(e) {
      const b = e.originalEvent.map._bounds;
      if (b !== undefined) {
        const x = b[1][0] - b[0][0];
        const y = b[1][1] - b[0][1]; 
        const kmPerDegreeLat = 111; 
        const kmPerDegreeLon = 111 * Math.cos(this.coords[0] * Math.PI / 180);
        const radiusInKm = Math.max(x * kmPerDegreeLon, y * kmPerDegreeLat);
        const minRadius = 0.1;
        if (radiusInKm > minRadius) {
          this.radius = radiusInKm;
        } else {
          this.radius = minRadius;
        }
        if (this.previousRadius === undefined || radiusInKm > this.previousRadius) {
          this.fetchStations();
        }
        this.previousRadius = radiusInKm;
      }

      this.stopUpdateMap();
      this.updateTimer = window.setTimeout(() => this.fetchStationsBrand(), 1000);
    },

    stopUpdateMap() {
      if (this.updateTimer != null) {
        window.clearTimeout(this.updateTimer)
        this.updateTimer = null
      }
    },

    onCloseModal() {
      this.isFueling = false
      this.activeOrder = null
    },

    // Проверяет, находится ли пользователь на станции, и делает её активной, если да
    autoSelectNearbyStation() {
      const proximityThreshold = 0.0005;
      const nearbyStation = this.stations.find(station => 
        Math.abs(station.coords[0] - this.coords[0]) < proximityThreshold &&
        Math.abs(station.coords[1] - this.coords[1]) < proximityThreshold
      );
      if (nearbyStation) {
        this.coords = [
          this.coords[0] + 0.0002,
          this.coords[1] + 0.0002 
        ];

        this.setSelected(nearbyStation);
        this.showStation(nearbyStation.id);
        this.isAutoSelect = true;
      }
    },

    updateFilter(selectedFuelTypes, selectedStationBrands) {
      this.selectedFuelTypes = selectedFuelTypes;
      this.selectedStationBrands = selectedStationBrands;
      this.fetchStations();
    },

    backToMap() {
      this.setSelected(null)
    }
  },
}
</script>