import stationsService from "@/services/stationsService";
import { forcePageErrorByStatus } from "@/helpers/cookieHelpers";

export default {
  namespaced: true,

  state: {
    stations: [],
    brands: [],
    station: [],
  },

  mutations: {
    SET_STATIONS(state, stations) {
      state.stations = stations;
    },
    SET_BRANDS(state, brands) {
      state.brands = brands;
    },
    SET_STATION(state, station) {
      state.station = station;
    },
  },

  actions: {
    async handleRequest({ commit }, { action, mutation, payload }) {
      try {
        const response = await action(payload);
        commit(mutation, response.data);
      } catch (error) {
        forcePageErrorByStatus(error);
      }
    },

    fetchStations({ dispatch }, data) {
      return dispatch("handleRequest", {
        action: stationsService.getStations,
        mutation: "SET_STATIONS",
        payload: data,
      });
    },

    fetchStationsBrand({ dispatch }) {
      return dispatch("handleRequest", {
        action: stationsService.getStationsBrand,
        mutation: "SET_BRANDS",
      });
    },

    stationInformation({ dispatch }, id ) {
      return dispatch("handleRequest", {
        action: stationsService.getStationInformation,
        mutation: "SET_STATION",
        payload: id,
      });
    },
  },

  getters: {
    getStations(state) {
      return state.stations;
    },
    getStationsBrand(state) {
      return state.brands;
    },
    getStationInformation(state) {
      return state.station;
    },
  },
};
