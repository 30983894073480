import fuelsService from "@/services/fuelsService";
import { forcePageErrorByStatus } from "@/helpers/cookieHelpers";

export default {
  namespaced: true,

  state: {
    fuels: [],
  },

  mutations: {
    SET_FUELS(state, data) {
      state.fuels = data;
    },
  },

  actions: {
    async handleRequest({ commit }, { action, mutation }) {
      try {
        const response = await action();
        commit(mutation, response.data);
      } catch (error) {
        forcePageErrorByStatus(error);
      }
    },

    fetchFuels({ dispatch }) {
      return dispatch("handleRequest", {
        action: fuelsService.getFuels,
        mutation: "SET_FUELS",
      });
    },
  },

  getters: {
    getFuels(state) {
      return state.fuels;
    },
  },
};
