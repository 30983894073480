<template>
  <YandexMapFuel />
</template>

<script>
import YandexMapFuel from "@/components/YandexMapFuel";
export default {
  name: "Main",
  components: {
    YandexMapFuel
  }
}
</script>
