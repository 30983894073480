var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('yandex-map',{attrs:{"settings":_vm.settings,"coords":_vm.coords,"cluster-options":_vm.clusterOptions,"controls":['zoomControl']},on:{"boundschange":_vm.updateMapTimer}},[_c('ymap-marker',{attrs:{"coords":_vm.coords,"marker-id":"my","hint-content":"Моё местоположение","icon":{
        glyph: 'AutoCircle',
        color: 'red'
      }}}),_vm._l((_vm.stations),function(station){return _c('ymap-marker',{key:station.id,attrs:{"coords":station.coords,"marker-id":station.id,"hint-content":station.name,"icon":{
        glyph: 'FuelStationCircle',
        color: 'blue'
      },"cluster-name":"1","balloon":{header: station.name, body: station.address}},on:{"click":function($event){return _vm.setSelected(station)}}})})],2),_c(_vm.getComponent,{tag:"component",attrs:{"stations-brand":_vm.stationsBrand,"fuels":_vm.fuels,"selected-fuel-types":_vm.selectedFuelTypes,"selected-station-brands":_vm.selectedStationBrands,"selected-station":_vm.selectedStation,"station-info":_vm.stationInfo,"balance":_vm.balance,"bonuses":_vm.bonuses,"on-close-modal":_vm.onCloseModal,"active-order":_vm.activeOrder},on:{"updateFilter":_vm.updateFilter,"showStation":_vm.showStation,"backToMap":_vm.backToMap}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }