<template>
  <div
    class="station"
    :class="{ open: isOpen, closed: !isOpen }"
  >
    <div
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div
        class="d-flex justify-content-center mb-3"
        style="height: 14px; width: 100%"
      >
        <SwipeIcon class="swipe" />
      </div>
      <div class="d-flex justify-content-between mb-2">
        <div
          class="link d-flex justify-content-center"
          @click="clickButtonBack"
        >
          <BackIcon class="image-back" />
        </div>
        <div class="ml-1">
          <div class="name">
            {{ station.name }}
          </div>
          <div class="address">
            {{ station.address }}
          </div>
        </div>
        <div style="width: 40px" />
      </div>
      <div
        class="d-flex justify-content-center mb-2"
        style="color: grey"
      >
        {{ getOperatingMode }}
      </div>
    </div>
    <div class="fuel">
      <div class="slide-container">
        <div
          v-for="(fuel, f) in station.fuels"
          :key="f"
          class="slide"
        >
          <div class="d-flex flex-column">
            <div class="type-name">
              {{ fuel.name }}
            </div>
          </div>
          <div class="sum">
            {{ fuel.price }}&nbsp;₽
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <button
        class="button"
        @click="pickStation(station.id)"
      >
        Заправиться здесь
      </button>
    </div>
  </div>
</template>

<script>
import BackIcon from '@/components/icon/BackIcon.vue';
import SwipeIcon from '@/components/icon/SwipeIcon.vue';

export default {
  name: "StationInformation",
  components: { BackIcon, SwipeIcon },
  props: {
    selectedStation: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      isOpen: true,
      touchStartY: 0,
      touchCurrentY: 0,
      station: this.selectedStation
    }
  },
  computed: {
    fuels() {
      if (this.station.fuel != null) {
        return this.station.fuel
      } else {
        return []
      }
    },

    getOperatingMode() {
      if (this.selectedStation?.postpay) {
        return "Станция работает в режиме постоплаты";
      }
      return "Станция работает в режиме предоплаты";
    },
  },
  watch: {
    selectedStation: {
      handler(newValue) {
        this.station = newValue;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
    },

    handleTouchMove(event) {
      this.touchCurrentY = event.touches[0].clientY;
    },

    handleTouchEnd(event) {
      const target = event.target;
      const isInteractive = target.closest('button') || target.closest('.link');
      if (isInteractive) {
        return;
      }
      const touchDiff = this.touchStartY - this.touchCurrentY;
      const threshold = 50;
      this.isOpen = touchDiff > threshold;
    },

    pickStation(id) {
      this.$emit('showStation', id);
    },
    
    clickButtonBack() {
      this.$emit('backToMap');
    }
  }
}
</script>

<style scoped>
  .name {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .address {
    font-size: 16px;
    font-weight: 400;
    color: #222A34;
    text-align: center;
  }

  .type-name {
    margin-top: 3px;
    font-size: 16px;
    color: #222A34;
    line-height: 24px;
  }

  .sum {
    margin: auto 0;
    font-size: 14px;
    color: #222A34;
  }

  .station {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 70vh;
    background: white;
    padding: 0 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: top 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    z-index: 1001;
  }

  .fuel {
    height: 100%;
    overflow: auto;
    padding-bottom: 0px;
  }

  .station.open {
    top: 30vh;
  }

  .station.closed {
    top: 70vh;
  }
  
  .slide-container {
    overflow-y: scroll;
    height: 100%;
    padding-top: 10px;
  }

  .slide {
    max-height: 65px;
    max-width: 340px;
    background: #F4F5FA;
    padding: 16px;
    margin: 8px auto;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 27px;
  }

  .button {
    max-width: 400px;
    z-index: 1001;
    min-width: 340px;
  }
</style>
