export default {
  namespaced: true,

  state: {
    successMessage: null,
    errorMessage: null,
  },

  mutations: {
    SET_SUCCESS_MESSAGE(state, message) {
      state.successMessage = message;
    },
    SET_ERROR_MESSAGE(state, error) {
      if (error.response.data.code >= 500) {
        state.errorMessage = 'Серверная ошибка, свяжитесь с поддержкой.'
      } else {
        state.errorMessage = error.response ? error.response.data.message : error.message;
      }
    },
  },

  actions: {
    addError({ commit }, error) {
      commit("SET_ERROR_MESSAGE", error);
    },

    addSuccess({ commit }, message) {
      commit("SET_SUCCESS_MESSAGE", message);
    },
  },

  getters: {
    getSuccessMessage(state) {
      return state.successMessage;
    },
    getErrorMessage(state) {
      return state.errorMessage;
    },
  },
};
