<template>
  <div
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div
      class="filter-station"
      :class="{ open: isOpen, closed: !isOpen }"
    >
      <div class="filter-container">
        <div
          class="d-flex justify-content-center mb-3"
          style="height: 14px; width: 100%"
        >
          <SwipeIcon class="swipe" />
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div class="filter-title">
            Фильтры
          </div>
          <button
            v-if="activeBrands.length > 0 || activeFuelTypes.length > 0"
            class="reset-button"
            @click="clearFilters"
          >
            Сбросить
          </button>
        </div>
        <div
          class="filter-scroll-container"
          :class="{ collapsed: !isOpen }"
          @scroll="handleScrollStart"
        >
          <div class="pt-2">
            <div class="fuel-type-container">
              <div class="title">
                Топливо
              </div>
              <button
                v-for="(fuel, index) in fuelType"
                :key="index"
                class="fuel-type-button"
                :class="{ active: activeFuelTypes.includes(fuel.id) }"
                @click="selectFuelType(fuel.id)"
              >
                {{ fuel.name }}
              </button>
            </div>
          </div>
          <div>
            <div
              class="title mt-4"
            >
              Бренд
            </div>
            <div
              v-for="(stationBrand, index) in stationBrands"
              :key="index"
              class="station-brand"
              role="button"
              @click="selectBrandSelection(stationBrand.id)"
            >
              <div class="d-flex align-items-center">
                <img
                  :src="`${entrypoint()}${stationBrand.image}`"
                  :alt="stationBrand.name"
                  class="logo-brand"
                >
                <div>{{ stationBrand.name }}</div>
              </div>
              <div class="select-brand">
                <div class="checkbox-wrapper-31">
                  <input
                    :id="'checkbox-' + stationBrand.id"
                    :value="stationBrand.id"
                    type="checkbox"
                    :checked="activeBrands.includes(stationBrand.id)"
                  >
                  <label :for="'checkbox-' + stationBrand.id">
                    <svg viewBox="0 0 35.6 35.6">
                      <circle
                        class="background"
                        cx="17.8"
                        cy="17.8"
                        r="17.8"
                      />
                      <circle
                        class="stroke"
                        cx="17.8"
                        cy="17.8"
                        r="14.37"
                      />
                      <polyline
                        class="check"
                        points="11.78 18.12 15.55 22.23 25.17 12.87"
                      />
                    </svg>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="action"
      @click="handleBrandTitleClick"
    />
    <div
      v-if="showEasterEgg"
      class="phantom"
    >
      <PhantomTrigger />
    </div>
  </div>
</template>

<script>
import {entrypoint} from "@/helpers/envHelpers";
import PhantomTrigger from "@/components/common/PhantomTrigger.vue";
import SwipeIcon from '@/components/icon/SwipeIcon.vue';


export default {
  name: 'FilterStations',
  components: { PhantomTrigger, SwipeIcon },
  props: {
    stationsBrand: {
      type: Array,
      default: () => [],
    },
    fuels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      isSwipe: false,
      isScrolling: false,
      touchStartY: 0,
      touchCurrentY: 0,
      fuelType: this.fuels,
      stationBrands: this.stationsBrand,
      activeFuelTypes: [],
      activeBrands: [],
      brandTitleClickCount: 0,
      showEasterEgg: false,
      clickTimeout: null,
    };
  },
  watch: {
    stationsBrand: {
      immediate: true,
      handler(newVal) {
        this.stationBrands = newVal;
      },
    },

    fuels: {
      immediate: true,
      handler(newVal) {
        this.fuelType = newVal;
      },
    }
  },
  methods: {
    entrypoint() {
      return entrypoint
    },

    handleTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
      this.isSwipe = false;
    },

    handleTouchMove(event) {
      if (!this.isScrolling) {
        this.touchCurrentY = event.touches[0].clientY;
        if (Math.abs(this.touchStartY - this.touchCurrentY) > 10) {
          this.isSwipe = true;
        }
      }
    },

    handleTouchEnd() {
      if (this.isSwipe && !this.isScrolling) {
        const touchDiff = this.touchStartY - this.touchCurrentY;
        const threshold = 50;
        this.isOpen = touchDiff > threshold;
      }
    },

    handleScrollStart() {
      this.isScrolling = true;
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 100);
    },

    selectFuelType(fuelId) {
      this.activeFuelTypes = this.activeFuelTypes.includes(fuelId)
        ? this.activeFuelTypes.filter(type => type !== fuelId)
        : [...this.activeFuelTypes, fuelId];
      this.$emit('updateFilter', this.activeFuelTypes, this.activeBrands);
    },

    selectBrandSelection(brandId) {
      this.activeBrands = this.activeBrands.includes(brandId)
        ? this.activeBrands.filter(id => id !== brandId)
        : [...this.activeBrands, brandId];
      this.$emit('updateFilter', this.activeFuelTypes, this.activeBrands);
    },
    
    clearFilters() {
      this.activeFuelTypes = [];
      this.activeBrands = [];
      this.$emit('updateFilter', this.activeFuelTypes, this.activeBrands);
    },

    handleBrandTitleClick() {
    if (this.brandTitleClickCount === 0) {
      this.clickTimeout = setTimeout(() => {
        this.brandTitleClickCount = 0;
      }, 3000);
    }
    this.brandTitleClickCount++;
    if (this.brandTitleClickCount === 7) {
      this.showEasterEgg = true;
      setTimeout(() => {
        this.showEasterEgg = false;
        this.brandTitleClickCount = 0;
        clearTimeout(this.clickTimeout);
      }, 5000);
    }
  },
  },
};
</script>

<style scoped>
  .filter-station {
    position: fixed;
    background: white;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: top 0.3s ease-in-out;
    padding: 0 16px;
    margin: 0 auto;
  }

  .filter-station.open {
    top: 0;
  }

  .filter-station.closed {
    top: 70vh;
  }

  .filter-container {
    max-width: 340px;
    margin: 0 auto;
  }

  .filter-title {
    font-size: 24px;
    font-weight: 700;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .reset-button {
    background: none;
    color: #00A3FF;
    border: 0 solid;
  }

  .filter-scroll-container {
    overflow-y: scroll;
    height: calc(100vh - 72px);
  }

  .filter-scroll-container.collapsed {
    height: 20vh;
  }

  .fuel-type-button {
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 12px;
    color: #222A34;
    background: #F4F5FA;
    z-index: 1001;
    box-shadow: 0 1px 20px 0 #0000000D;
    cursor: pointer;
    border: 0 solid;
    padding: 10px 16px;
    margin: 0 8px 8px 0;
  }

  .fuel-type-button.active {
    background: #00a3ff;
    color: white;
  }

  .station-brand {
    height: 60px;
    display: flex;
    justify-content: space-between;
  }

  .logo-brand {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .select-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
  }

  .action {
    z-index: 2000;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
  }
</style>
