import qs from 'qs';
import axios from 'axios';
import { getCookie } from '../helpers/cookieHelpers';

export const apiHost = `${process.env.VUE_APP_API_HOST}/benzuber/api`;

export const apiClient = axios.create({
  baseURL: apiHost,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json',
  },
});

apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie('user-token');

apiClient.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });
