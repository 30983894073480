<template>
  <div>
    <div class="head">
      Выберите колонку
    </div>
    <div class="dispenser">
      <div
        v-for="(fuelDispenser, k) in fuelDispensers"
        :key="k"
        class="dispenser-item"
      >
        <a
          class="link dispenser-item__link"
          @click="selectFuelDispenser(fuelDispenser)"
        >
          <div
            class="fuel-dispenser"
          >
            {{ fuelDispenser.number }}
          </div>
          <div
            v-for="(fuel, f) in fuelDispenser.fuels"
            :key="f"
            class="fuel-text"
          >
            {{ fuel.name }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectFuelDispenser',
  props: {
    fuelDispensers: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectFuelDispenser(fuelDispenser) {
      this.$emit('selectFuelDispenser', fuelDispenser);
    }
  }
}
</script>

<style scoped>
  .head {
    color: #8A898E;
    font-size: 24px;
    margin-top: 36px;
    margin-bottom: 112px;
  }

  .dispenser {
    display: flex;
    overflow-x: auto;
    padding-left: 50%;
    margin: 30px auto;
    max-height: 336px;
    scroll-behavior: smooth;
    width: 100%;
    justify-content: start;
  }

  .dispenser-item {
    scroll-snap-align: start;
    flex: 0 0 auto;
    margin: 0 4px;
    background: #F4F5FA;
    border-radius: 20px;
    width: 154px;
    padding: 0 8px 8px;
  }

  .dispenser-item:first-child {
    margin-left: -76px;
  }

  .dispenser-item__link {
    height: 100%;
    width: 100%;
  }

  .dispenser::-webkit-scrollbar {
    display: none;
  }

  .fuel-dispenser {
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    text-align: center;
    color: #222A34;
    margin-bottom: 20px;
    margin-top: 24px;
  }


  .fuel-text {
    font-size: 14px;
    line-height: 1.2em;
    color: #222A34;
    margin-bottom: 8px;
  }

  .fuel-text:last-of-type {
    margin-bottom: 24px;
  }

  .link {
    text-decoration: none;
  }

  .link:hover {
    text-decoration: none;
  }
</style>
