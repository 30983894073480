<template>
  <div>
    <Notification />
    <router-view />
  </div>
</template>


<script>
import Notification from './components/common/Notification.vue';

export default {
  name: 'App',
  components: {
    Notification,
  },
}
</script>

<style>
  .ymap-container {
    width: 100vw;
    height: 100vh;
  }
</style>
