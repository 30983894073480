import { apiClient, apiHost } from "@/store/api";

export default {
  createOrder(data) {
    return apiClient.post(apiHost + '/v1/order/create', data)
  },
  checkActiveOrder() {
    return apiClient.get(apiHost + '/v1/order')
  },
  getOrder(id) {
    return apiClient.get(`${apiHost}/v1/order/${id}/info`)
  },
  cancelOrder(id){
    return apiClient.post(`${apiHost}/v1/order/${id}/cancel`)
  },
  createPostpayOrder(data) {
    return apiClient.post(apiHost + '/v1/order/postpay', data)
  },
  updatePostpayOrder(data) {
    return apiClient.put(apiHost + '/v1/order/postpay', data);
  },
  getPostpayOrder(id) {
    return apiClient.put(`${apiHost}/v1/order/postpay/${id}`);
  }
}
